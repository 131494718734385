import React, { useState, useEffect } from 'react';
import { Edit2, Trash2, UserPlus, PlusCircle, X, Paperclip, Save, FileX } from 'lucide-react';
import { openDB } from 'idb';
import CustomProgressBar from './CustomProgressBar';

const dbPromise = openDB('proiectDB', 1, {
  upgrade(db) {
    db.createObjectStore('tasks', { keyPath: 'id', autoIncrement: true });
    db.createObjectStore('files', { keyPath: 'id', autoIncrement: true });
  },
});

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        {children}
      </div>
    </div>
  );
};

const statusColors = {
  'Neînceput': { bg: 'bg-gray-100', border: 'border-gray-300', text: 'text-gray-800' },
  'În progres': { bg: 'bg-blue-50', border: 'border-blue-300', text: 'text-blue-800' },
  'În revizie': { bg: 'bg-yellow-50', border: 'border-yellow-300', text: 'text-yellow-800' },
  'Blocat': { bg: 'bg-red-50', border: 'border-red-300', text: 'text-red-800' },
  'Finalizat': { bg: 'bg-green-50', border: 'border-green-300', text: 'text-green-800' }
};

const ProjectPipeline = () => {
  const [tasks, setTasks] = useState([]);
  const [editingTask, setEditingTask] = useState(null);
  const [editingTaskData, setEditingTaskData] = useState(null);
  const [editingSubtask, setEditingSubtask] = useState(null);
  const [newTask, setNewTask] = useState({ name: '', status: 'Neînceput', dueDate: '', assignees: [], subtasks: [], files: [] });
  const [filePreview, setFilePreview] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [editingMember, setEditingMember] = useState(null);

  useEffect(() => {
    loadTasks();
  }, []);

  const loadTasks = async () => {
    const db = await dbPromise;
    const storedTasks = await db.getAll('tasks');
    setTasks(storedTasks);
  };

  const saveTasks = async (updatedTasks) => {
    const db = await dbPromise;
    const tx = db.transaction('tasks', 'readwrite');
    const store = tx.objectStore('tasks');
    for (const task of updatedTasks) {
      await store.put(task);
    }
    await tx.done;
    setTasks(updatedTasks);
  };

  const addTask = async () => {
    if (newTask.name && newTask.dueDate) {
      const db = await dbPromise;
      const tx = db.transaction('tasks', 'readwrite');
      const store = tx.objectStore('tasks');
      const id = await store.add({
        ...newTask,
        assignees: [],
        subtasks: [],
        files: []
      });
      await tx.done;
      const addedTask = await db.get('tasks', id);
      setTasks([...tasks, addedTask]);
      setNewTask({ name: '', status: 'Neînceput', dueDate: '', assignees: [], subtasks: [], files: [] });
    }
  };

  const startEditing = (task) => {
    setEditingTask(task.id);
    setEditingTaskData({ ...task });
  };

  const handleEditChange = (field, value) => {
    setEditingTaskData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const saveTaskEdit = async () => {
    if (!editingTaskData) return;
    
    const updatedTasks = tasks.map(task => 
      task.id === editingTaskData.id ? editingTaskData : task
    );
    await saveTasks(updatedTasks);
    setEditingTask(null);
    setEditingTaskData(null);
  };

  const deleteTask = async (id) => {
    setModalContent({
      title: "Confirmare ștergere",
      message: "Sunteți sigur că doriți să ștergeți această sarcină și toate fișierele asociate?",
      onConfirm: async () => {
        const db = await dbPromise;
        const tx = db.transaction(['tasks', 'files'], 'readwrite');
        const taskToDelete = tasks.find(task => task.id === id);
        
        // Delete associated files
        for (const file of taskToDelete.files) {
          await tx.objectStore('files').delete(file.id);
        }
        
        // Delete the task
        await tx.objectStore('tasks').delete(id);
        
        await tx.done;
        
        const updatedTasks = tasks.filter(task => task.id !== id);
        setTasks(updatedTasks);
        setModalOpen(false);
      }
    });
    setModalOpen(true);
  };

  const addAssignee = async (taskId) => {
    setModalContent({
      title: "Adaugă membru nou",
      content: (
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Inițiale membru"
            id="memberInitials"
            className="w-full p-2 border rounded"
          />
          <input
            type="file"
            accept="image/*"
            id="memberImage"
            className="w-full p-2 border rounded"
          />
        </div>
      ),
      onConfirm: async () => {
        const initials = document.getElementById('memberInitials').value;
        const imageFile = document.getElementById('memberImage').files[0];
        if (initials) {
          let imageUrl = null;
          if (imageFile) {
            imageUrl = await new Promise((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.readAsDataURL(imageFile);
            });
          }
          const updatedTasks = tasks.map(task => 
            task.id === taskId 
              ? { ...task, assignees: [...task.assignees, { id: Date.now(), initials: initials.toUpperCase(), image: imageUrl }] }
              : task
          );
          await saveTasks(updatedTasks);
          setModalOpen(false);
        }
      }
    });
    setModalOpen(true);
  };

  const editAssignee = async (taskId, assigneeId, newInitials, newImage) => {
    const updatedTasks = tasks.map(task => 
      task.id === taskId 
        ? { 
            ...task, 
            assignees: task.assignees.map(assignee => 
              assignee.id === assigneeId 
                ? { ...assignee, initials: newInitials.toUpperCase(), image: newImage || assignee.image } 
                : assignee
            ) 
          }
        : task
    );
    await saveTasks(updatedTasks);
    setEditingMember(null);
  };

  const deleteAssignee = async (taskId, assigneeId) => {
    setModalContent({
      title: "Confirmare ștergere membru",
      message: "Sunteți sigur că doriți să ștergeți acest membru?",
      onConfirm: async () => {
        const updatedTasks = tasks.map(task => 
          task.id === taskId 
            ? { ...task, assignees: task.assignees.filter(assignee => assignee.id !== assigneeId) }
            : task
        );
        await saveTasks(updatedTasks);
        setModalOpen(false);
      }
    });
    setModalOpen(true);
  };

  const addSubtask = async (taskId) => {
    const newSubtask = { 
      id: Date.now(),
      name: '', 
      startDate: '', 
      dueDate: '', 
      status: 'not_started', 
      assignee: null 
    };
    const updatedTasks = tasks.map(task => 
      task.id === taskId 
        ? { ...task, subtasks: [...task.subtasks, newSubtask] }
        : task
    );
    await saveTasks(updatedTasks);
    setEditingSubtask({ taskId, subtaskId: newSubtask.id, data: newSubtask });
  };

  const updateSubtask = async (taskId, subtaskId) => {
    if (!editingSubtask || editingSubtask.taskId !== taskId || editingSubtask.subtaskId !== subtaskId) return;

    const updatedTasks = tasks.map(task => 
      task.id === taskId 
        ? { ...task, subtasks: task.subtasks.map(subtask => 
            subtask.id === subtaskId ? { ...subtask, ...editingSubtask.data } : subtask
          )}
        : task
    );
    await saveTasks(updatedTasks);
    setEditingSubtask(null);
  };

  const handleSubtaskChange = (field, value) => {
    setEditingSubtask(prev => ({
      ...prev,
      data: { ...prev.data, [field]: value }
    }));
  };

  const toggleSubtask = async (taskId, subtaskId) => {
    const updatedTasks = tasks.map(task => 
      task.id === taskId 
        ? { ...task, subtasks: task.subtasks.map(subtask => 
            subtask.id === subtaskId ? { ...subtask, status: subtask.status === 'completed' ? 'in_progress' : 'completed' } : subtask
          )}
        : task
    );
    await saveTasks(updatedTasks);
  };

  const deleteSubtask = async (taskId, subtaskId) => {
    setModalContent({
      title: "Confirmare ștergere",
      message: "Sunteți sigur că doriți să ștergeți această mini-sarcină?",
      onConfirm: async () => {
        const updatedTasks = tasks.map(task => 
          task.id === taskId 
            ? { ...task, subtasks: task.subtasks.filter(subtask => subtask.id !== subtaskId) }
            : task
        );
        await saveTasks(updatedTasks);
        setModalOpen(false);
      }
    });
    setModalOpen(true);
  };
  const [error, setError] = useState(null);
  const attachFile = async (taskId, file) => {
    console.log('Starting file attachment for task:', taskId);
    console.log('File to attach:', file);
  
    try {
      const db = await dbPromise;
      
      return new Promise((resolve, reject) => {
        const tx = db.transaction(['tasks'], 'readwrite');
        const taskStore = tx.objectStore('tasks');
  
        tx.oncomplete = () => {
          console.log('Transaction completed successfully');
          resolve();
        };
  
        tx.onerror = (event) => {
          console.error('Transaction error:', event.target.error);
          reject(event.target.error);
        };
  
        const getRequest = taskStore.get(taskId);
  
        getRequest.onsuccess = async (event) => {
          const task = event.target.result;
          if (!task) {
            reject(new Error('Task not found'));
            return;
          }
  
          // Inițializăm array-ul de fișiere dacă nu există
          if (!task.files) {
            task.files = [];
          }
  
          // Convertim fișierul într-un ArrayBuffer
          const arrayBuffer = await file.arrayBuffer();
  
          // Generăm un ID unic pentru fișier
          const fileId = Date.now();
  
          // Adăugăm noul fișier direct în task
          task.files.push({
            id: fileId,
            name: file.name,
            type: file.type,
            lastModified: file.lastModified,
            data: arrayBuffer
          });
  
          // Actualizăm task-ul cu noul fișier inclus
          const putRequest = taskStore.put(task);
  
          putRequest.onsuccess = () => {
            console.log('Task updated successfully with new file');
          };
  
          putRequest.onerror = (putEvent) => {
            console.error('Error updating task:', putEvent.target.error);
            reject(putEvent.target.error);
          };
        };
  
        getRequest.onerror = (event) => {
          console.error('Error getting task:', event.target.error);
          reject(event.target.error);
        };
      });
    } catch (error) {
      console.error('Error in attachFile:', error);
      throw error;
    }
  };
  
  // Folosirea funcției attachFile
  const handleAttachFile = async (taskId, file) => {
    try {
      await attachFile(taskId, file);
      
      // Actualizăm starea locală
      setTasks(prevTasks => 
        prevTasks.map(t => 
          t.id === taskId 
            ? { ...t, files: [...(t.files || []), { id: Date.now(), name: file.name }] }
            : t
        )
      );
  
      setFilePreview(null);
      console.log('File attached and state updated successfully');
    } catch (error) {
      console.error('Error handling file attachment:', error);
      setError('A apărut o eroare la atașarea fișierului. Vă rugăm să încercați din nou.');
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview({
          file: file,
          name: file.name,
          url: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const downloadFile = async (fileId, fileName) => {
    const db = await dbPromise;
    const fileObj = await db.get('files', fileId);
    if (fileObj && fileObj.data) {
      const blob = new Blob([fileObj.data], { type: fileObj.type });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else {
      console.error('File not found');
    }
  };

  const deleteFile = async (taskId, fileId) => {
    setModalContent({
      title: "Confirmare ștergere fișier",
      message: "Sunteți sigur că doriți să ștergeți acest fișier?",
      onConfirm: async () => {
        const db = await dbPromise;
        await db.delete('files', fileId);
        
        const updatedTasks = tasks.map(task => 
          task.id === taskId 
            ? { ...task, files: task.files.filter(file => file.id !== fileId) }
            : task
        );
        await saveTasks(updatedTasks);
        setModalOpen(false);
      }
    });
    setModalOpen(true);
  };

  return (
    
    <div className="max-w-6xl mx-auto p-6 font-sans bg-gray-100">
      <h1 className="text-4xl font-bold mb-8 text-gray-800 border-b pb-4">Managementul Proiectului</h1>
      {error && (
      <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
        <p>{error}</p>
        <button onClick={() => setError(null)} className="font-bold hover:text-red-800">Închide</button>
      </div>
    )}
      {/* Add new task form */}
      <div className="mb-8 bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700">Adaugă sarcină nouă</h2>
        <div className="flex flex-col space-y-4">
          <input
            type="text"
            placeholder="Nume sarcină"
            value={newTask.name}
            onChange={(e) => setNewTask({...newTask, name: e.target.value})}
            className="border border-gray-300 rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
          />
          <select
            value={newTask.status}
            onChange={(e) => setNewTask({...newTask, status: e.target.value})}
            className="border border-gray-300 rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
          >
            {Object.keys(statusColors).map(status => (
              <option key={status} value={status}>{status}</option>
            ))}
          </select>
          <input
            type="date"
            value={newTask.dueDate}
            onChange={(e) => setNewTask({...newTask, dueDate: e.target.value})}
            className="border border-gray-300 rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
          />
          <button 
            onClick={addTask} 
            className="bg-blue-600 text-white rounded-md p-3 hover:bg-blue-700 transition-colors flex items-center justify-center shadow-sm"
          >
            <UserPlus size={20} className="mr-2" /> Adaugă sarcină
          </button>
        </div>
      </div>

      {/* Tasks list */}
      <div className="space-y-6">
        {tasks.map(task => (
          <div 
            key={task.id} 
            className={`bg-white p-6 rounded-lg shadow-lg border-l-8 ${statusColors[task.status].border} ${statusColors[task.status].bg} transition-all duration-300 ease-in-out`}
          >
            {/* Task header */}
            <div className="flex justify-between items-center mb-4">
              {editingTask === task.id ? (
                <div className="flex flex-col space-y-2 w-full">
                  <input
                    type="text"
                    value={editingTaskData.name}
                    onChange={(e) => handleEditChange('name', e.target.value)}
                    className="font-bold text-xl text-gray-800 border-b border-gray-300 focus:outline-none focus:border-blue-500 w-full"
                  />
                  <div className="flex space-x-4">
                    <select
                      value={editingTaskData.status}
                      onChange={(e) => handleEditChange('status', e.target.value)}
                      className="text-sm text-gray-600 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      {Object.keys(statusColors).map(status => (
                        <option key={status} value={status}>{status}</option>
                      ))}
                    </select>
                    <input
                      type="date"
                      value={editingTaskData.dueDate}
                      onChange={(e) => handleEditChange('dueDate', e.target.value)}
                      className="text-sm text-gray-600 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                  <div className="flex justify-end space-x-2">
                    <button 
                      onClick={() => {
                        setEditingTask(null);
                        setEditingTaskData(null);
                      }} 
                      className="bg-gray-300 text-gray-800 rounded-md p-2 hover:bg-gray-400 transition-colors"
                    >
                      Anulează
                    </button>
                    <button 
                      onClick={saveTaskEdit} 
                      className="bg-green-500 text-white rounded-md p-2 hover:bg-green-600 transition-colors flex items-center"
                    >
                      <Save size={20} className="mr-2" /> Salvează
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <h3 className={`font-bold text-2xl ${statusColors[task.status].text}`}>{task.name}</h3>
                  <div className="flex space-x-2">
                    <button onClick={() => startEditing(task)} className="text-blue-600 hover:text-blue-800 transition-colors p-1 rounded-full hover:bg-blue-100">
                      <Edit2 size={20} />
                    </button>
                    <button onClick={() => deleteTask(task.id)} className="text-red-600 hover:text-red-800 transition-colors p-1 rounded-full hover:bg-red-100">
                      <Trash2 size={20} />
                    </button>
                  </div>
                </>
              )}
            </div>
            
            {/* Task details */}
            {!editingTask && (
              <div className="mb-4 flex justify-between items-center">
                <p className="text-sm text-gray-600">Termen limită: <span className="font-semibold">{task.dueDate}</span></p>
                <span className={`px-3 py-1 rounded-full text-sm font-semibold ${statusColors[task.status].bg} ${statusColors[task.status].text}`}>
                  {task.status}
                </span>
              </div>
            )}
            
            {/* Task assignees */}
            <div className="flex items-center space-x-2 mb-4">
              {task.assignees.map((assignee) => (
                <div key={assignee.id} className="relative group">
                  <div className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center text-sm font-bold text-gray-700 overflow-hidden transition-transform group-hover:scale-110">
                    {assignee.image ? (
                      <img src={assignee.image} alt={assignee.initials} className="w-full h-full object-cover" />
                    ) : (
                      assignee.initials
                    )}
                  </div>
                  <div className="absolute bottom-0 right-0 transform translate-x-1/4 translate-y-1/4 opacity-0 group-hover:opacity-100 transition-opacity">
                    <button
                      onClick={() => setEditingMember(assignee.id)}
                      className="bg-blue-500 rounded-full p-1 text-white text-xs mr-1 hover:bg-blue-600"
                    >
                      <Edit2 size={10} />
                    </button>
                    <button
                      onClick={() => deleteAssignee(task.id, assignee.id)}
                      className="bg-red-500 rounded-full p-1 text-white text-xs hover:bg-red-600"
                    >
                      <X size={10} />
                    </button>
                  </div>
                </div>
              ))}
              <button 
                onClick={() => addAssignee(task.id)} 
                className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center hover:bg-gray-300 transition-colors"
              >
                <UserPlus size={20} />
              </button>
            </div>

            {/* Subtasks */}
            <div className="space-y-2 mb-4">
              {task.subtasks.map(subtask => (
                <div key={subtask.id} className="flex items-center justify-between bg-gray-50 p-3 rounded-md shadow-sm hover:bg-gray-100 transition-colors">
                  {editingSubtask && editingSubtask.taskId === task.id && editingSubtask.subtaskId === subtask.id ? (
                    <div className="flex-grow flex space-x-2">
                      <input
                        type="text"
                        value={editingSubtask.data.name}
                        onChange={(e) => handleSubtaskChange('name', e.target.value)}
                        className="flex-grow border border-gray-300 rounded p-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                      <input
                        type="date"
                        value={editingSubtask.data.startDate}
                        onChange={(e) => handleSubtaskChange('startDate', e.target.value)}
                        className="border border-gray-300 rounded p-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                      <input
                        type="date"
                        value={editingSubtask.data.dueDate}
                        onChange={(e) => handleSubtaskChange('dueDate', e.target.value)}
                        className="border border-gray-300 rounded p-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                      <button onClick={() => updateSubtask(task.id, subtask.id)} className="bg-green-500 text-white rounded p-1 flex items-center hover:bg-green-600 transition-colors">
                        <Save size={16} className="mr-1" /> Salvează
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          checked={subtask.status === 'completed'}
                          onChange={() => toggleSubtask(task.id, subtask.id)}
                          className="rounded text-blue-500 focus:ring-blue-500"
                        />
                        <span className={subtask.status === 'completed' ? 'line-through text-gray-500' : 'text-gray-700'}>
                          {subtask.name} (până la {subtask.dueDate})
                        </span>
                      </div>
                      <div className="flex items-center space-x-2">
                        {subtask.assignee && (
                          <div className="w-6 h-6 rounded-full bg-gray-300 flex items-center justify-center text-xs font-bold text-gray-700">
                            {subtask.assignee.initials}
                          </div>
                        )}
                        <button onClick={() => setEditingSubtask({ taskId: task.id, subtaskId: subtask.id, data: { ...subtask } })} className="text-blue-500 hover:text-blue-700 transition-colors p-1 rounded-full hover:bg-blue-100">
                          <Edit2 size={14} />
                        </button>
                        <button onClick={() => deleteSubtask(task.id, subtask.id)} className="text-red-500 hover:text-red-700 transition-colors p-1 rounded-full hover:bg-red-100">
                          <X size={14} />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ))}
              <button 
                onClick={() => addSubtask(task.id)} 
                className="text-blue-600 hover:text-blue-800 transition-colors flex items-center mt-2"
              >
                <PlusCircle size={18} className="mr-1" /> Adaugă mini-sarcină
              </button>
            </div>

            {/* File attachments */}
            <div className="mb-4">
              <h4 className="font-semibold mb-2 text-gray-700">Fișiere atașate:</h4>
              <div className="space-y-2">
                {task.files && task.files.map(file => (
                  <div key={file.id} className="flex items-center justify-between bg-gray-50 p-2 rounded-md hover:bg-gray-100 transition-colors">
                    <div className="flex items-center space-x-2">
                      <Paperclip size={16} className="text-gray-500" />
                      <span className="text-sm text-gray-700">{file.name}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <button onClick={() => downloadFile(file.id, file.name)} className="text-blue-500 hover:text-blue-700 transition-colors p-1 rounded-full hover:bg-blue-100">
                        Descarcă
                      </button>
                      <button onClick={() => deleteFile(task.id, file.id)} className="text-red-500 hover:text-red-700 transition-colors p-1 rounded-full hover:bg-red-100">
                        <FileX size={16} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-2">
                <label className="block mb-2 text-sm font-medium text-gray-700" htmlFor={`file-upload-${task.id}`}>
                  Încarcă fișier
                </label>
                <input
                  type="file"
                  id={`file-upload-${task.id}`}
                  onChange={handleFileChange}
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                />
                {filePreview && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-600">Previzualizare: {filePreview.name}</p>
                    {filePreview.url.startsWith('data:image') ? (
                      <img src={filePreview.url} alt="Preview" className="mt-2 max-w-xs rounded" />
                    ) : (
                      <p className="text-sm text-gray-500">Fișier selectat (nu poate fi previzualizat)</p>
                    )}
                    <button
                      onClick={() => handleAttachFile(task.id, filePreview.file)}
                      className="mt-2 bg-blue-500 text-white rounded-md p-2 hover:bg-blue-600 transition-colors"
                    >
                      Atașează fișierul
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Progress bar */}
            <CustomProgressBar subtasks={task.subtasks} />
            <div className="flex justify-between text-xs text-gray-600 mt-1">
              <span>{task.subtasks[0]?.startDate || 'N/A'}</span>
              <span>{task.dueDate}</span>
            </div>
          </div>
        ))}
      </div>

      {/* Tip */}
      <div className="mt-8 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 rounded-lg shadow" role="alert">
        <p className="font-bold">Sfat</p>
        <p>Folosiți mini-sarcinile pentru a diviza și urmări progresul fiecărei sarcini principale. Puteți adăuga responsabili individuali pentru fiecare mini-sarcină și atașa fișiere relevante.</p>
      </div>

      {/* Modal */}
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <h2 className="text-xl font-bold mb-4">{modalContent?.title}</h2>
        {modalContent?.message && <p className="mb-4">{modalContent.message}</p>}
        {modalContent?.content}
        <div className="mt-4 flex justify-end space-x-2">
          <button onClick={() => setModalOpen(false)} className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded transition-colors">
            Anulează
          </button>
          <button onClick={modalContent?.onConfirm} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors">
            Confirmă
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ProjectPipeline;